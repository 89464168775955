import * as React from "react"
import { useEffect } from 'react';
import './styles/donation-failure.scss';
import Layout from "../components/layout"
import Seo from "../components/seo";
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { navigateUrl, URL, getLanguageDefault } from "../utils/Utils";

const DonationFailurePage = (props) => {
  const isBrowser = () => typeof localStorage !== "undefined";

  const { t, i18n } = props;
  const firstName = isBrowser() ? localStorage.getItem('donator_firstname') : '';
  const region = isBrowser() ? localStorage.getItem('region_code') : '';
  var timeoutLanguage:any = null;

  useEffect(() => {
    if(timeoutLanguage) {
      clearTimeout(timeoutLanguage);
    }
    timeoutLanguage = setTimeout(() => {
      i18n.changeLanguage(getLanguageDefault().code.toLowerCase());
    }, 100);
  }, []);

  const navigateToDonate = () => {
    navigateUrl(`/?${URL.REGION}=${region}`);
  }

  return (
    <Layout isShowAction={false} minHeight="100%">
    <Seo title="Donation Falure" />
    <div className="donation-failed">
      <div className="failed-container">
        <div className="page-failed-wrapper row">
            <h1 className="title">{t('dearText')} {firstName},</h1>
            <h1 className="description">{t('titleFailed')}</h1>
            <div className="btn-start">
              <Button  
                label={t('startOver')} 
                className="p-button-rounded btn-submit" 
                icon="fa-solid fa-chevron-right" 
                iconPos="right" 
                onClick={() => navigateToDonate()}
              />
            </div>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default withTranslation('blog')(DonationFailurePage);
